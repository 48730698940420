export const SKILLS = [
  {
    title: "Languages",
    icon: "./assets/images/frontend-icon.png",
       skills: [
      { skill: "Python", percentage: "100%", proficiency:"Proficient" },
      { skill: "Java", percentage: "100%", proficiency:"Proficient" },
      { skill: "JavaScript", percentage: "80%", proficiency:"Highly Skilled" },
    ],

  },
  {
    title: "Web",
    icon: "./assets/images/backend-icon.png",
    skills: [
      { skill: "JavaScript", percentage: "80%", proficiency:"Highly Skilled" },
      { skill: "HTML5", percentage: "75%", proficiency:"Relatively Experienced" },
      { skill: "CSS (Vanilla/Tailwind)", percentage: "75%", proficiency:"Relatively Experienced" },
    ],
  },
  {
    title: "Web Frameworks",
    icon: "./assets/images/tools-icon.png",
    skills: [
      { skill: "FastAPI", percentage: "85%", proficiency:"Experienced" },
      { skill: "React.js", percentage: "70%", proficiency:"Experienced" },
    ],
  },
  {
    title: "General Skills",
    icon: "./assets/images/soft-skills-icon.png",
    skills: [
      { skill: "Problem-solving", percentage: "100%", proficiency:"Excellent" },
      { skill: "Collaboration", percentage: "100%", proficiency:"Excellent" },
      { skill: "Attention to Detail", percentage: "100%", proficiency:"Excellent" },
    ],
  },
];

export const WORK_EXPERIENCE = [
  {
    title: "FRC Robotics Programmer",
    date: "August 2023 - Present",
    responsibilities: [
      "Utilize applications such as WPILib VSCode to program a functioning FRC Robot.",
      "Programmed time-based functions and autonomous trajectories for robots using Java.",
      "Advanced use of using technologies such as OpenCV to control robots using visual queues.",
    ],
  },
     {
    title: "Lead Developer - Selenium Based Project",
    date: "July 2023 - November 2023",
    responsibilities: [
      "Used technologies such as Selenium, Python, and Docker to develop an Arbitrage Betting application.",
      "Applied technical knowledge to store data scraped from betting websites using Selenium.",
      "Deployed the mathematical algorithm using Docker and gave it dummy information to test accuracy.",
    ],
  },
];
