import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Hero from "./components/Hero/Hero";
import Skills from "./components/Skills/Skills";
import WorkExperience from "./components/WorkExperience/WorkExperience";
import ContactMe from "./components/ContactMe/ContactMe";
import Footer from "./components/Footer/Footer";
import AOS from 'aos'
import 'aos/dist/aos.css'
import {useEffect} from "react";

const App = () => {

    useEffect(() => {
        AOS.init({
            duration:2000,
            delay: 100,
        })
    }, []);


  return (
      <div>
          <Navbar/>
              <div className="container">
                  <div className="animation" data-aos="fade-up">
                    <Hero/>
                  </div>
                  <div className="animation" data-aos="flip-right">
                    <Skills/>
                  </div>
                  <div className="animation" data-aos="fade-zoom-in">
                    <WorkExperience/>
                  </div>
              </div>
              <div className="animation" data-aos="fade-up">
                <ContactMe/>
              </div>
          <Footer/>
      </div>
  );
};

export default App;
