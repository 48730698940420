// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
  margin: 4rem 15%;
  position: relative;
}

.contact-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.contact-content {
  display: flex;
  gap: 3rem;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column-reverse;
  }

  .contact-container h5 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactMe/ContactMe.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE;IACE,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,qBAAqB;EACvB;AACF","sourcesContent":[".contact-container {\n  margin: 4rem 15%;\n  position: relative;\n}\n\n.contact-container h5 {\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin-bottom: 3rem;\n}\n\n.contact-content {\n  display: flex;\n  gap: 3rem;\n}\n\n@media (max-width: 768px) {\n  .contact-content {\n    flex-direction: column-reverse;\n  }\n\n  .contact-container h5 {\n    font-size: 1.3rem;\n    margin-bottom: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
