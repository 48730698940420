// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-card {
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 2.5rem;
  text-align: center;
  background: rgba(22, 17, 47, 0.398);
  -webkit-backdrop-filter: blur(1rem);
          backdrop-filter: blur(1rem);
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.skills-card:hover,
.skills-card.active {
  background: linear-gradient(180deg, #aa94fe 0%, #7c5fe6 100%);
}

.skills-card span {
  font-size: 1.3rem;
  font-weight: 500;
}

.skill-icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2a2252;
  border-radius: 0.65rem;
  border: 1.5px solid #6852ba;
  position: absolute;
  top: -1rem;
  left: -1rem;
}

.skill-icon img {
  width: 2.4rem;
  height: auto;
  object-fit: contain;
}

@media (max-width: 1025px) {
  .skills-card {
    padding: 2rem;
  }
  .skills-card span {
    font-size: 1rem;
  }
  .skill-icon {
    width: 3rem;
    height: 3rem;
  }

  .skill-icon img {
    width: 1.8rem;
  }
}

@media (max-width: 768px) {
  .skills-card span {
    font-size: 0.9rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Skills/SkillCard/SkillCard.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,kBAAkB;EAClB,mCAAmC;EACnC,mCAA2B;UAA3B,2BAA2B;EAC3B,kBAAkB;EAClB,eAAe;EACf,yBAAyB;AAC3B;;AAEA;;EAEE,6DAA6D;AAC/D;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,2BAA2B;EAC3B,kBAAkB;EAClB,UAAU;EACV,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,aAAa;EACf;EACA;IACE,eAAe;EACjB;EACA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,aAAa;EACf;AACF;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".skills-card {\n  border-radius: 0.65rem;\n  border: 1.5px solid #6751b9;\n  padding: 2.5rem;\n  text-align: center;\n  background: rgba(22, 17, 47, 0.398);\n  backdrop-filter: blur(1rem);\n  position: relative;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.skills-card:hover,\n.skills-card.active {\n  background: linear-gradient(180deg, #aa94fe 0%, #7c5fe6 100%);\n}\n\n.skills-card span {\n  font-size: 1.3rem;\n  font-weight: 500;\n}\n\n.skill-icon {\n  width: 4rem;\n  height: 4rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background: #2a2252;\n  border-radius: 0.65rem;\n  border: 1.5px solid #6852ba;\n  position: absolute;\n  top: -1rem;\n  left: -1rem;\n}\n\n.skill-icon img {\n  width: 2.4rem;\n  height: auto;\n  object-fit: contain;\n}\n\n@media (max-width: 1025px) {\n  .skills-card {\n    padding: 2rem;\n  }\n  .skills-card span {\n    font-size: 1rem;\n  }\n  .skill-icon {\n    width: 3rem;\n    height: 3rem;\n  }\n\n  .skill-icon img {\n    width: 1.8rem;\n  }\n}\n\n@media (max-width: 768px) {\n  .skills-card span {\n    font-size: 0.9rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
