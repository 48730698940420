// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-menu {
  width: 100vw;
  height: 100vh;
  display: none;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999 !important;
  opacity: 0;
  box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  transform: translateX(-100vw);
}

.mobile-menu-container {
  width: 60vw;
  height: 100vh;
  background-color: #342864;
  padding: 2rem;
}

.mobile-menu.active {
  opacity: 1;
  transform: translateX(0);
}

.mobile-menu img {
  margin-bottom: 3rem;
}

.mobile-menu ul {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  list-style: none;
  margin-left: -2rem;
}

@media (max-width: 769px) {
  .mobile-menu {
    display: block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Navbar/MobileNav/MobileNav.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,aAAa;EACb,oCAAoC;EACpC,eAAe;EACf,OAAO;EACP,MAAM;EACN,uBAAuB;EACvB,UAAU;EACV,4CAA4C;EAC5C,yBAAyB;EACzB,6BAA6B;AAC/B;;AAEA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,UAAU;EACV,wBAAwB;AAC1B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".mobile-menu {\n  width: 100vw;\n  height: 100vh;\n  display: none;\n  background-color: rgba(0, 0, 0, 0.3);\n  position: fixed;\n  left: 0;\n  top: 0;\n  z-index: 999 !important;\n  opacity: 0;\n  box-shadow: 0px 29px 80px rgba(0, 0, 0, 0.3);\n  transition: all 0.3s ease;\n  transform: translateX(-100vw);\n}\n\n.mobile-menu-container {\n  width: 60vw;\n  height: 100vh;\n  background-color: #342864;\n  padding: 2rem;\n}\n\n.mobile-menu.active {\n  opacity: 1;\n  transform: translateX(0);\n}\n\n.mobile-menu img {\n  margin-bottom: 3rem;\n}\n\n.mobile-menu ul {\n  display: flex;\n  flex-direction: column;\n  gap: 2rem;\n  list-style: none;\n  margin-left: -2rem;\n}\n\n@media (max-width: 769px) {\n  .mobile-menu {\n    display: block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
