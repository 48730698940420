// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.work-experience-card {
  background: #130f2a;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 1.5rem;
  margin: 0 1rem;
}

.work-experience-card h6 {
  font-size: 1.05rem;
  font-weight: 500;
  margin-bottom: 0.7rem;
}

.work-duration {
  display: inline-block;
  font-size: 0.7rem;
  font-weight: 400;
  background: rgba(103, 81, 185, 0.5);
  border-radius: 0.3rem;
  padding: 0.4rem 0.6rem;
  margin-bottom: 1.3rem;
}

.work-experience-card ul li {
  list-style: none;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
  position: relative;
}

.work-experience-card ul li::after {
  content: " ";
  width: 0.5rem;
  height: 0.5rem;
  background-color: #6751b9;
  border-radius: 0.5rem;
  position: absolute;
  left: -1.3rem;
  top: 0.35rem;
}

@media (max-width: 768px) {
  .work-experience-card {
    margin: 0;
  }

  .work-experience-card h6 {
    font-size: 0.95rem;
  }

  .work-experience-card ul li {
    font-size: 0.75rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/WorkExperience/ExperienceCard/ExperienceCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;EACjB,gBAAgB;EAChB,mCAAmC;EACnC,qBAAqB;EACrB,sBAAsB;EACtB,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,cAAc;EACd,yBAAyB;EACzB,qBAAqB;EACrB,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE;IACE,SAAS;EACX;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".work-experience-card {\n  background: #130f2a;\n  border-radius: 0.65rem;\n  border: 1.5px solid #6751b9;\n  padding: 1.5rem;\n  margin: 0 1rem;\n}\n\n.work-experience-card h6 {\n  font-size: 1.05rem;\n  font-weight: 500;\n  margin-bottom: 0.7rem;\n}\n\n.work-duration {\n  display: inline-block;\n  font-size: 0.7rem;\n  font-weight: 400;\n  background: rgba(103, 81, 185, 0.5);\n  border-radius: 0.3rem;\n  padding: 0.4rem 0.6rem;\n  margin-bottom: 1.3rem;\n}\n\n.work-experience-card ul li {\n  list-style: none;\n  font-size: 0.8rem;\n  font-weight: 400;\n  margin-bottom: 0.5rem;\n  position: relative;\n}\n\n.work-experience-card ul li::after {\n  content: \" \";\n  width: 0.5rem;\n  height: 0.5rem;\n  background-color: #6751b9;\n  border-radius: 0.5rem;\n  position: absolute;\n  left: -1.3rem;\n  top: 0.35rem;\n}\n\n@media (max-width: 768px) {\n  .work-experience-card {\n    margin: 0;\n  }\n\n  .work-experience-card h6 {\n    font-size: 0.95rem;\n  }\n\n  .work-experience-card ul li {\n    font-size: 0.75rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
