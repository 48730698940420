// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-details-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #130f2a;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.contact-details-card .icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #3d3072;
  margin-bottom: 0.8rem;
}

.icon img {
  width: 1.8rem;
  height: auto;
  object-fit: contain;
}

.contact-details-card p {
  font-size: 0.9rem;
  font-weight: 400;
  text-align: center;
}

@media (max-width: 400px) {
  .contact-details-card p {
    font-size: 0.8rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ContactMe/ContactInfoCard/ContactInfoCard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,2BAA2B;EAC3B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".contact-details-card {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  background: #130f2a;\n  border-radius: 0.65rem;\n  border: 1.5px solid #6751b9;\n  padding: 1.5rem;\n  margin-bottom: 2rem;\n}\n\n.contact-details-card .icon {\n  width: 4rem;\n  height: 4rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 0.5rem;\n  background: #3d3072;\n  margin-bottom: 0.8rem;\n}\n\n.icon img {\n  width: 1.8rem;\n  height: auto;\n  object-fit: contain;\n}\n\n.contact-details-card p {\n  font-size: 0.9rem;\n  font-weight: 400;\n  text-align: center;\n}\n\n@media (max-width: 400px) {\n  .contact-details-card p {\n    font-size: 0.8rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
