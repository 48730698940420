// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-container {
  margin: 8rem 0;
  position: relative;
}

.experience-container h5 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

.experience-container::after,
.experience-container::before {
  content: " ";
  width: 20rem;
  height: 20rem;
  border-radius: 28.125rem;
  background: #7c66e3;
  position: absolute;
  right: 20%;
  z-index: -1;
  opacity: 0.3;
  filter: blur(60px);
  -webkit-filter: blur(60px);
}

.arrow-left,
.arrow-right {
  width: 2.2rem;
  height: 2.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6751b9;
  border-radius: 0.65rem;
  border: 1.5px solid #6751b9;
  background: #130f2a;
  position: absolute;
  top: 55%;
  z-index: 2;
  cursor: pointer;
}

.arrow-left span,
.arrow-right span {
  font-size: 2rem;
  font-weight: 500;
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right: 0;
}

@media (max-width: 768px) {
  .experience-container {
    margin: 4rem 0;
  }

  .experience-container h5 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }

  .arrow-left {
    left: -1rem;
  }

  .arrow-right {
    right: -1rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/WorkExperience/WorkExperience.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;;EAEE,aAAa;EACb,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,cAAc;EACd,sBAAsB;EACtB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,eAAe;AACjB;;AAEA;;EAEE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,OAAO;AACT;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".experience-container {\n  margin: 8rem 0;\n  position: relative;\n}\n\n.experience-container h5 {\n  font-size: 1.5rem;\n  font-weight: 600;\n  margin-bottom: 3rem;\n}\n\n.experience-container::after,\n.experience-container::before {\n  content: \" \";\n  width: 20rem;\n  height: 20rem;\n  border-radius: 28.125rem;\n  background: #7c66e3;\n  position: absolute;\n  right: 20%;\n  z-index: -1;\n  opacity: 0.3;\n  filter: blur(60px);\n  -webkit-filter: blur(60px);\n}\n\n.arrow-left,\n.arrow-right {\n  width: 2.2rem;\n  height: 2.2rem;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: #6751b9;\n  border-radius: 0.65rem;\n  border: 1.5px solid #6751b9;\n  background: #130f2a;\n  position: absolute;\n  top: 55%;\n  z-index: 2;\n  cursor: pointer;\n}\n\n.arrow-left span,\n.arrow-right span {\n  font-size: 2rem;\n  font-weight: 500;\n}\n\n.arrow-left {\n  left: 0;\n}\n\n.arrow-right {\n  right: 0;\n}\n\n@media (max-width: 768px) {\n  .experience-container {\n    margin: 4rem 0;\n  }\n\n  .experience-container h5 {\n    font-size: 1.3rem;\n    margin-bottom: 2rem;\n  }\n\n  .arrow-left {\n    left: -1rem;\n  }\n\n  .arrow-right {\n    right: -1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
