import React from "react";
import "./ContactMe.css";
import ContactInfoCard from "./ContactInfoCard/ContactInfoCard";
const ContactMe = () => {
  return (
    <section id="contact" className="contact-container">
      <h5>Contact Me</h5>

        <div className="contact-content">
            <div style={{flex: 1}}>
                <ContactInfoCard
                    iconUrl="./assets/images/email-icon.svg"
                    text="vasko1087@gmail.com"
                    href="https://mailto:vasko1087@gmail.com"
                />
            </div>
            <div style={{flex: 1}}>
                <ContactInfoCard
                    iconUrl="./assets/images/github-icon.svg"
                    text="@vasilm1"
                    href="https://github.com/vasilm1"
                />
            </div>
        </div>
    </section>
  );
};

export default ContactMe;
