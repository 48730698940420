import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <section id="hero" className="hero-container">
      <div className="hero-content">
        <h2>Hello I'm</h2>
        <p>
          <span className="text_1">Vasil</span>
          <span className="text_2">Software Engineer</span>
        </p>
      </div>

      <div className="hero-img">
        <div>

        <div className="tech-icon-left">
            <div className="tech-icon">
              <img src="./assets/images/python.png" alt=""/>
            </div>
            <div className="tech-icon">
              <img src="./assets/images/java.png" alt=""/>
            </div>
            <div className="tech-icon">
              <img src="./assets/images/selenium.png" alt=""/>
            </div>
            <div className="tech-icon">
              <img src="./assets/images/react.png" alt=""/>
            </div>
          </div>

          <img src="./assets/images/img11.png" alt=""/>
        </div>

        <div>
          <div className="tech-icon">
            <img src="./assets/images/html.png" alt=""/>
          </div>
          <div className="tech-icon">
            <img src="./assets/images/css.png" alt=""/>
          </div>
          <div className="tech-icon">
            <img src="./assets/images/js.png" alt=""/>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
